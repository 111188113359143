<template>
  <div v-if="loading" class="flex justify-center items-center py-12">
    <div class="animate-pulse text-gray-500">Loading pricing...</div>
  </div>

  <div v-else-if="error" class="text-center py-12 text-red-600">
    {{ error }}
  </div>

  <div v-else>
    <div
      v-if="!props.limitSelectionTo || props.limitSelectionTo == 'subscription'"
      class="mt-12 flex justify-center"
    >
      <fieldset aria-label="Payment frequency">
        <RadioGroup
          v-model="selectedFrequency"
          :class="`grid grid-cols-${DEFAULT_FREQUENCIES.length} gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200`"
        >
          <RadioGroupOption
            as="template"
            v-for="option in DEFAULT_FREQUENCIES"
            :key="option.value"
            :value="option"
            v-slot="{checked}"
          >
            <div
              :class="[
                checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                'cursor-pointer rounded-full px-4 py-1'
              ]"
            >
              {{ option.label }}
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </fieldset>
    </div>
    <div
      class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-2xl xl:max-w-5xl xl:grid-cols-3"
    >
      <div
        v-for="tier in currentTiers"
        :key="tier.id"
        :class="[
          tier.mostPopular ? 'ring-2 ring-blue-600' : 'ring-1 ring-gray-200',
          'rounded-3xl p-8'
        ]"
      >
        <h2
          :id="tier.id"
          :class="[tier.mostPopular ? 'text-blue-600' : 'text-gray-900', 'text-lg/8 font-semibold']"
        >
          {{ tier.name }}
        </h2>
        <p class="hidden mt-4 text-sm/6 text-gray-600">{{ tier.description }}</p>
        <p class="mt-2 flex items-baseline gap-x-1">
          <span class="text-4xl font-semibold tracking-tight text-gray-900">{{ tier.price }}</span>
          <span class="text-sm/6 font-semibold text-gray-600">{{
            selectedFrequency.priceSuffix
          }}</span>
        </p>
        <div class="mt-2 hidden">
          <p class="text-sm text-gray-500">
            {{ calculateCostPerDoc(tier) }}
          </p>
        </div>
        <a
          :href="tier.href"
          :aria-describedby="tier.id"
          :class="[
            tier.mostPopular
              ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500'
              : 'text-blue-600 ring-1 ring-inset ring-blue-200',
            'mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600',
            getButtonProps(tier).disabled
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer hover:ring-blue-300 hover:bg-blue-50'
          ]"
          :disabled="getButtonProps(tier).disabled"
          @click="handleTierClick(tier)"
        >
          {{ getButtonProps(tier).label }}
        </a>
        <ul role="list" class="mt-8 space-y-3 text-sm/6 text-gray-600">
          <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
            <CheckIcon class="h-6 w-5 flex-none text-blue-600" aria-hidden="true" />
            {{ feature }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import {RadioGroup, RadioGroupOption} from '@headlessui/vue';
import {CheckIcon} from '@heroicons/vue/20/solid';
import {computed, onMounted, ref, watch} from 'vue';
import {pricesService} from '~/lib/services/prices.service';

// Add new props
const props = defineProps({
  isLandingPage: {
    type: Boolean,
    default: false
  },
  isOnboarding: {
    type: Boolean,
    default: false
  },
  hasActiveSubscription: {
    type: Boolean,
    default: false
  },
  currentSubscription: {
    type: Object,
    default: null
  },
  limitSelectionTo: {
    type: String,
    default: null
  }
});

const prices = ref(null);
const loading = ref(true);
const error = ref(null);
const emit = defineEmits(['tier-selected']);

// Default frequency options as a constant
let DEFAULT_FREQUENCIES = [
  {value: 'monthly', label: 'Monthly', priceSuffix: '/month'},
  {value: 'yearly', label: 'Yearly (Save 20%)', priceSuffix: '/year'},
  {value: 'one-time', label: 'One-Time', priceSuffix: '/one-time'}
];

// Initialize with the default one-time option
const selectedFrequency = ref(DEFAULT_FREQUENCIES[0]);

// Fetch prices on component mount
onMounted(async () => {
  try {
    const {data, error: fetchError} = await pricesService.fetchPrices();
    if (fetchError) {
      throw new Error(fetchError.message);
    }

    // Transform the array into an object with separate monthly and yearly subscriptions
    prices.value = data.reduce((acc, price) => {
      const tierType = price.custom_data?.tier_type;
      const billingCycle = price.billing_cycle?.interval;

      // Determine the category
      let category = 'addon'; // default
      if (tierType === 'subscription') {
        category = `subscription-${billingCycle}ly`; // becomes 'subscription-month' or 'subscription-year'
      } else if (tierType === 'one-time') {
        category = 'one-time';
      }

      if (!acc[category]) {
        acc[category] = {};
      }

      if (price.custom_data?.tier_id) {
        acc[category][price.custom_data.tier_id] = {
          price_id: price.id,
          unit_price: parseInt(price.unit_price.amount),
          currency: price.unit_price.currency_code,
          paddleProductId: price.product_id,
          credits: price.custom_data.tier_credits,
          billing_cycle: billingCycle || null,
          name: price.name,
          description: price.description
        };
      }
      return acc;
    }, {});

    console.log(prices.value);

    loading.value = false;
  } catch (err) {
    error.value = 'Failed to load pricing information';
    loading.value = false;
  }
});

const pricing = computed(() => ({
  frequencies: DEFAULT_FREQUENCIES,
  oneTimeTiers: [
    {
      name: prices.value?.['one-time']?.['tier-starter-onetime']?.name || 'Starter Pack',
      id: 'tier-starter-onetime',
      paddleProductId: prices.value?.['one-time']?.['tier-starter-onetime']?.paddleProductId,
      priceId: prices.value?.['one-time']?.['tier-starter-onetime']?.price_id,
      price: formatPrice(
        prices.value?.['one-time']?.['tier-starter-onetime']?.unit_price,
        prices.value?.['one-time']?.['tier-starter-onetime']?.currency
      ),
      priceAmount: prices.value?.['one-time']?.['tier-starter-onetime']?.unit_price,
      credits: prices.value?.['one-time']?.['tier-starter-onetime']?.credits,
      description:
        prices.value?.['one-time']?.['tier-starter-onetime']?.description ||
        'The perfect entry point for individuals and small users.',
      features: [
        `${prices.value?.['one-time']?.['tier-starter-onetime']?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: false
    },
    {
      name: prices.value?.['one-time']?.['tier-personal-onetime']?.name || 'Personal Archive',
      id: 'tier-personal-onetime',
      paddleProductId: prices.value?.['one-time']?.['tier-personal-onetime']?.paddleProductId,
      priceId: prices.value?.['one-time']?.['tier-personal-onetime']?.price_id,
      price: formatPrice(
        prices.value?.['one-time']?.['tier-personal-onetime']?.unit_price,
        prices.value?.['one-time']?.['tier-personal-onetime']?.currency
      ),
      priceAmount: prices.value?.['one-time']?.['tier-personal-onetime']?.unit_price,
      credits: prices.value?.['one-time']?.['tier-personal-onetime']?.credits,
      description:
        prices.value?.['one-time']?.['tier-personal-onetime']?.description ||
        'Great value with more flexibility and a higher document volume.',
      features: [
        `${prices.value?.['one-time']?.['tier-personal-onetime']?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: true
    },
    {
      name: prices.value?.['one-time']?.['tier-business-onetime']?.name || 'Business Archive',
      id: 'tier-business-onetime',
      paddleProductId: prices.value?.['one-time']?.['tier-business-onetime']?.paddleProductId,
      priceId: prices.value?.['one-time']?.['tier-business-onetime']?.price_id,
      price: formatPrice(
        prices.value?.['one-time']?.['tier-business-onetime']?.unit_price,
        prices.value?.['one-time']?.['tier-business-onetime']?.currency
      ),
      priceAmount: prices.value?.['one-time']?.['tier-business-onetime']?.unit_price,
      credits: prices.value?.['one-time']?.['tier-business-onetime']?.credits,
      description:
        prices.value?.['one-time']?.['tier-business-onetime']?.description ||
        'Tailored for power users with demanding document volumes.',
      features: [
        `${prices.value?.['one-time']?.['tier-business-onetime']?.credits} documents`,
        'Documents never expire',
        'Smart Intelligence included',
        'Email support'
      ],
      mostPopular: false
    }
  ],
  monthySubscriptionTiers: [
    {
      name:
        prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.name || 'Personal Plus',
      id: 'tier-personal-monthly',
      paddleProductId:
        prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.paddleProductId,
      priceId: prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.price_id,
      price: formatPrice(
        prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.unit_price,
        prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.currency
      ),
      priceAmount: prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.unit_price,
      credits: prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.credits,
      description:
        prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.description ||
        'The perfect entry point for individuals and small users.',
      features: [
        `${prices.value?.['subscription-monthly']?.['tier-personal-monthly']?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: false
    },
    {
      name: prices.value?.['subscription-monthly']?.['tier-business-monthly']?.name || 'Business',
      id: 'tier-business-monthly',
      paddleProductId:
        prices.value?.['subscription-monthly']?.['tier-business-monthly']?.paddleProductId,
      priceId: prices.value?.['subscription-monthly']?.['tier-business-monthly']?.price_id,
      price: formatPrice(
        prices.value?.['subscription-monthly']?.['tier-business-monthly']?.unit_price,
        prices.value?.['subscription-monthly']?.['tier-business-monthly']?.currency
      ),
      priceAmount: prices.value?.['subscription-monthly']?.['tier-business-monthly']?.unit_price,
      credits: prices.value?.['subscription-monthly']?.['tier-business-monthly']?.credits,
      description:
        prices.value?.['subscription-monthly']?.['tier-business-monthly']?.description ||
        'Great value with more flexibility and a higher document volume.',
      features: [
        `${prices.value?.['subscription-monthly']?.['tier-business-monthly']?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: true
    }
  ],
  yearlySubscriptionTiers: [
    {
      name:
        prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.name || 'Personal Plus',
      id: 'tier-personal-yearly',
      paddleProductId:
        prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.paddleProductId,
      priceId: prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.price_id,
      price: formatPrice(
        prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.unit_price,
        prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.currency
      ),
      priceAmount: prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.unit_price,
      credits: prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.credits,
      description:
        prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.description ||
        'The perfect entry point for individuals and small users.',
      features: [
        `${prices.value?.['subscription-yearly']?.['tier-personal-yearly']?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: false
    },
    {
      name: prices.value?.['subscription-yearly']?.['tier-business-yearly']?.name || 'Business',
      id: 'tier-business-yearly',
      paddleProductId:
        prices.value?.['subscription-yearly']?.['tier-business-yearly']?.paddleProductId,
      priceId: prices.value?.['subscription-yearly']?.['tier-business-yearly']?.price_id,
      price: formatPrice(
        prices.value?.['subscription-yearly']?.['tier-business-yearly']?.unit_price,
        prices.value?.['subscription-yearly']?.['tier-business-yearly']?.currency
      ),
      priceAmount: prices.value?.['subscription-yearly']?.['tier-business-yearly']?.unit_price,
      credits: prices.value?.['subscription-yearly']?.['tier-business-yearly']?.credits,
      description:
        prices.value?.['subscription-yearly']?.['tier-business-yearly']?.description ||
        'Great value with more flexibility and a higher document volume.',
      features: [
        `${prices.value?.['subscription-yearly']?.['tier-business-yearly']?.credits} documents per month`,
        'Smart Intelligence included',
        'Email support',
        'Cancel anytime'
      ],
      mostPopular: true
    }
  ]
}));

// Ensure frequency has a valid value
watch(
  () => prices.value,
  newPrices => {
    if (!newPrices) {
      // Reset to default one-time option if prices fail to load
      selectedFrequency.value = DEFAULT_FREQUENCIES[0];
    }
  }
);

// Watch props.limitSelectionTo and update the frequency accordingly
watch(
  () => props.limitSelectionTo,
  newLimitSelectionTo => {
    if (newLimitSelectionTo === 'one-time') {
      selectedFrequency.value = DEFAULT_FREQUENCIES.find(f => f.value === 'one-time');
    } else if (newLimitSelectionTo === 'subscription') {
      selectedFrequency.value = DEFAULT_FREQUENCIES.find(f => f.value === 'monthly');
    } else {
      selectedFrequency.value = DEFAULT_FREQUENCIES[0];
    }

    // If the limitSelectionTo is subscription, remove the one-time option
    if (props.limitSelectionTo == 'subscription') {
      DEFAULT_FREQUENCIES = DEFAULT_FREQUENCIES.filter(f => f.value !== 'one-time');
    }
  }
);

const currentTiers = computed(() => {
  // Fallback to one-time tiers if frequency is somehow invalid
  const isValidFrequency = DEFAULT_FREQUENCIES.some(f => f.value === selectedFrequency.value.value);
  if (!isValidFrequency) {
    selectedFrequency.value = DEFAULT_FREQUENCIES[0];
  }

  if (selectedFrequency.value.value === 'yearly') {
    return pricing.value.yearlySubscriptionTiers;
  }

  return selectedFrequency.value.value === 'one-time'
    ? pricing.value.oneTimeTiers
    : pricing.value.monthySubscriptionTiers;
});

// Helper function to format prices
function formatPrice(amount, currency) {
  if (!amount || !currency) return '';

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0
  }).format(amount / 100); // Divide by 100 as Paddle returns amounts in cents
}

function calculateCostPerDoc(tier) {
  if (!tier.priceAmount) return '';

  // Convert from cents to dollars/euros/etc
  const priceInMainUnit = tier.priceAmount / 100;

  // For yearly subscriptions, divide by 12 to get monthly cost
  const monthlyPrice =
    selectedFrequency.value.value === 'yearly' ? priceInMainUnit / 12 : priceInMainUnit;

  // Simple division for both one-time and subscription
  const costPerDoc = (monthlyPrice / tier.credits).toFixed(3);
  const formattedCost = formatCostPerDoc(costPerDoc);

  if (selectedFrequency.value.value === 'one-time') {
    return `${formattedCost} per document`;
  } else {
    return `${formattedCost} per document`;
  }
}

function formatCostPerDoc(cost) {
  // Convert to cents if less than $1
  if (parseFloat(cost) < 1) {
    const cents = (parseFloat(cost) * 100).toFixed(1);
    return `${cents}¢`;
  }

  // Format as currency if $1 or more
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: prices.value?.['one-time']?.['tier-starter-onetime']?.currency || 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);
}

// Add new computed property for button labels
const getButtonProps = computed(() => tier => {
  const isSubscription =
    selectedFrequency.value.value === 'monthly' || selectedFrequency.value.value === 'yearly';
  let isCurrentPlan = false;

  // Check if the current plan is the same as the selected tier
  if (isSubscription && props.currentSubscription) {
    isCurrentPlan =
      isSubscription && Number(tier.credits) === props.currentSubscription.total_credits;
  }

  if (props.isLandingPage) {
    return {
      label: 'Get started',
      disabled: false
    };
  } else if (props.isOnboarding) {
    return {
      label: 'Continue to Checkout',
      disabled: false
    };
  } else if (isSubscription) {
    if (isCurrentPlan) {
      return {
        label: 'Current plan',
        disabled: true
      };
    } else if (!props.hasActiveSubscription) {
      return {
        label: 'Upgrade to subscription',
        disabled: false
      };
    }
  }

  return {
    label: 'Buy pack',
    disabled: false
  };
});

function handleTierClick(tier) {
  // If the tier is disabled, do nothing
  if (getButtonProps.value(tier).disabled) return;

  // If we are on the landing page, redirect to the register page
  if (props.isLandingPage) {
    navigateTo('/app/register');
  }

  // If we are on the onboarding page, emit an event to the parent
  emit('tier-selected', tier);
}
</script>
